import { useState } from "react";
import Modal from "./Modal";
import play_bg from "../assets/play_bg.png";
import r_icon from "../assets/r_icon.png";
import { Rating, Star } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";

const reviewStyles = {
  itemShapes: Star,
  activeFillColor: "#FFCF29",
  inactiveFillColor: "#fbf1a9",
};

function Result({ category, sheetData, filteredData, cats }) {
  const [showModal, setShowModal] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");

  const getNameBySlug = (slug) => {
    const category = cats.find((item) => item.slug === slug);
    return category ? category.name : null;
  };

  return (
    <>
      <Modal
        type="iframe"
        show={showModal}
        handleClose={() => {
          setShowModal(0);
          setVideoUrl("");
        }}
      >
        <iframe
          style={{ width: "100%", height: "500px" }}
          src={`https://www.youtube.com/embed/${videoUrl}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>

      <table>
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Business Partner</th>
            <th style={{ width: "30%" }}>Company Website</th>
            <th style={{ width: "35%" }}>Description</th>
            {/* <th>Categories</th> */}
          </tr>
        </thead>
        <tbody>
          {category === "all"
            ? sheetData.map((item) => (
                <tr key={Math.random()}>
                  <td>{item.name}</td>
                  <td>
                    <a
                      href={item.website}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        window.dataLayer.push({
                          event: "business-click",
                          businessURL: item.website,
                        });
                      }}
                    >
                      {
                        item.website
                          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                          .split("/")[0]
                      }
                    </a>
                  </td>
                  <td>{item.description}</td>
                  {/* <td>{item.categories.join(', ')}</td> */}
                </tr>
              ))
            : filteredData.map((item) => {
                let sponsor_class = "";
                if (item.sponsor_index === 1) {
                  sponsor_class = "platinum-sponsor";
                } else if (item.sponsor_index === 2) {
                  sponsor_class = "gold-sponsor";
                } else if (item.sponsor_index === 3) {
                  sponsor_class = "silver-sponsor";
                }

                let img_url = "";
                if (item.sponsor_logo) {
                  const source_url = new URL(item.sponsor_logo);
                  const img_id = source_url.searchParams.get("id");
                  img_url = `https://drive.google.com/thumbnail?id=${img_id}&sz=w1000`;
                }

                let first_col = null;
                if (item.sponsor_index === 1) {
                  first_col = (
                    <>
                      <div className="featured-sponsor">
                        <img src={r_icon} alt="R Icon" /> <span>|</span>{" "}
                        Featured {getNameBySlug(category)} Firm
                      </div>
                      <div className="business-partner">
                        <div>
                          <img
                            src={play_bg}
                            alt=""
                            className="company-img"
                            onClick={() => {
                              setShowModal(1);
                              setVideoUrl(
                                item.video_url.match(/([a-z0-9_-]{11})/gim)[0]
                              );
                            }}
                          />
                        </div>
                        <div>
                          <img
                            src={img_url}
                            alt=""
                            className={`sponsor-logo ${
                              item.border_sponsor_logo === "YES"
                                ? "img-border"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </>
                  );
                } else if (
                  item.sponsor_index === 2 ||
                  item.sponsor_index === 3
                ) {
                  first_col = (
                    <>
                      <div className="featured-sponsor">
                        <img src={r_icon} alt="R Icon" /> <span>|</span>{" "}
                        Featured {getNameBySlug(category)} Firm
                      </div>
                      <div className="business-partner">
                        <div>
                          <img
                            src={item.placeholder_image}
                            alt=""
                            className="company-img"
                          />
                        </div>
                        <div>
                          <img
                            src={img_url}
                            alt=""
                            className={`sponsor-logo ${
                              item.border_sponsor_logo === "YES"
                                ? "img-border"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </>
                  );
                } else {
                  first_col = item.name;
                }

                let second_col = null;
                if (item.sponsor_index === 1) {
                  second_col = (
                    <>
                      {item.rating && item.reviews_count ? (
                        <div className="rating">
                          <Rating
                            style={{ maxWidth: 100 }}
                            value={item.rating}
                            itemStyles={reviewStyles}
                            readOnly
                          />
                          <strong>{item.rating}/5.0</strong>
                          <span>({item.reviews_count} Reviews)</span>
                        </div>
                      ) : (
                        ""
                      )}

                      <a
                        href={item.website}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          window.dataLayer.push({
                            event: "business-click",
                            businessURL: item.website,
                          });
                        }}
                        className="sponsor-link link-platinum"
                      >
                        <span>{item.cta_button_title}</span> <span>&rarr;</span>
                      </a>
                    </>
                  );
                } else if (item.sponsor_index === 2) {
                  second_col = (
                    <>
                      {item.rating && item.reviews_count ? (
                        <div className="rating">
                          <Rating
                            style={{ maxWidth: 100 }}
                            value={item.rating}
                            itemStyles={reviewStyles}
                            readOnly
                          />
                          <strong>{item.rating}/5.0</strong>
                          <span>({item.reviews_count} Reviews)</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <a
                        href={item.website}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          window.dataLayer.push({
                            event: "business-click",
                            businessURL: item.website,
                          });
                        }}
                        className="sponsor-link"
                      >
                        {item.cta_button_title} &#8594;
                      </a>
                    </>
                  );
                } else if (item.sponsor_index === 3) {
                  second_col = (
                    <>
                      {item.rating && item.reviews_count ? (
                        <div className="rating">
                          <Rating
                            style={{ maxWidth: 100 }}
                            value={item.rating}
                            itemStyles={reviewStyles}
                            readOnly
                          />
                          <strong>{item.rating}/5.0</strong>
                          <span>({item.reviews_count} Reviews)</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <a
                        href={item.website}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          window.dataLayer.push({
                            event: "business-click",
                            businessURL: item.website,
                          });
                        }}
                        className="sponsor-link"
                      >
                        {item.cta_button_title} &#8594;
                      </a>
                    </>
                  );
                } else {
                  second_col = (
                    <a
                      href={item.website}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        window.dataLayer.push({
                          event: "business-click",
                          businessURL: item.website,
                        });
                      }}
                    >
                      {
                        item.website
                          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                          .split("/")[0]
                      }
                    </a>
                  );
                }

                let third_col = null;
                if (item.sponsor_index === 1) {
                  third_col = (
                    <div className="description">
                      <img
                        src={item.description_image}
                        alt=""
                        className={`${
                          item.border_description_image === "YES"
                            ? "img-border"
                            : ""
                        }`}
                      />
                      <h4>{item.description_heading}</h4>
                      {item.description}
                    </div>
                  );
                } else if (
                  item.sponsor_index === 2 ||
                  item.sponsor_index === 3
                ) {
                  third_col = (
                    <div className="description">
                      <h4>{item.description_heading}</h4>
                      {item.description}
                    </div>
                  );
                } else {
                  third_col = item.description;
                }

                return (
                  <tr key={Math.random()} className={sponsor_class}>
                    <td>{first_col}</td>
                    <td>{second_col}</td>
                    <td>{third_col}</td>
                    {/* <td>{item.categories.join(', ')}</td> */}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </>
  );
}

export default Result;
