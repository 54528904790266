import Select from "react-select";
import { useEffect, useState } from "react";
import info_icon from "../assets/info_icon.png";
import Modal from "./Modal";

function Category({
  category,
  categories,
  handleCategoryChange,
  sheetData,
  filteredData,
  categoryData,
  urlCategory,
  displayHeader,
  setDisplayHeader,
}) {
  const [showModal, setShowModal] = useState(0);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const opt = [];
    const cats = [];
    categories.forEach((val) => {
      opt.push({
        value: val
          .toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, "")
          .replace(/[\s_-]+/g, "-")
          .replace(/^-+|-+$/g, ""),
        label: val,
      });
      cats.push(
        val
          .toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, "")
          .replace(/[\s_-]+/g, "-")
          .replace(/^-+|-+$/g)
      );
    });
    opt.sort((a, b) => a.value.localeCompare(b.value));
    opt.unshift({ value: "all", label: "All" });
    setOptions(opt);

    if (urlCategory && cats && cats.includes(urlCategory)) {
      handleCategoryChange({ value: urlCategory });
      setDisplayHeader(false);
    } else {
      setDisplayHeader(true);
    }
    // eslint-disable-next-line
  }, [categories]);

  let categoryDes = {};
  if (category !== "all") {
    categoryDes = categoryData.filter(
      (val) => category.trim() === val.category.trim()
    );
  }

  return (
    <>
      {displayHeader && (
        <div className="filters">
          <strong>Partner Category:</strong>{" "}
          <Select
            options={options}
            placeholder="All"
            onChange={(obj) => {
              handleCategoryChange(obj);
            }}
            styles={{
              menuList: (baseStyles) => ({
                ...baseStyles,
                minHeight: "500px",
                textAlign: "left",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                maxWidth: 300,
                height: 40,
                fontSize: 14,
                margin: "20px auto 0",
                textAlign: "left",
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                maxWidth: 500,
                fontSize: 14,
                left: 0,
                right: 0,
                margin: "0 auto",
                textAlign: "left",
              }),
            }}
          />
          <span>
            {category === "all" ? sheetData.length : filteredData.length}{" "}
            Results
          </span>
          {category !== "all" &&
          (categoryDes[0]?.description ||
            filteredData[0].category_description) ? (
            <img
              src={info_icon}
              alt="Info"
              className="info-icon"
              onClick={() => setShowModal(1)}
            />
          ) : (
            ""
          )}
        </div>
      )}

      <Modal show={showModal} handleClose={() => setShowModal(0)}>
        <p>
          {category !== "all"
            ? filteredData[0].category_description ||
              categoryDes[0]?.description
            : ""}
        </p>
      </Modal>
    </>
    /*
      <select
        value={category}
        onChange={handleCategoryChange}
        onBlur={(e) => (e.size = 0)}
        onMouseDown={(e) => (e.size = 8)}
      >
        <option value="all">All</option>
        {!categories
          ? ""
          : categories.map((item) => (
              <option key={Math.random()} value={item.trim()}>
                {item.trim()}
              </option>
            ))}
      </select>
    */
  );
}

export default Category;
